.form-control {
    margin-top: 10px;
    height: 48px;
    border: 2px solid #eee;
    border-radius: 10px;
  }
  .form-control:focus {
    box-shadow: none;
    border: 2px solid #039be5;
  }
  .wrapper {
    overflow: scroll;
    height: 100vh;
  }
  
  hr {
    border: none;
    height: 5px;
    color: #333;
    background-color: #333;
  }
  