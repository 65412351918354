@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

body {
  font-family: "Open Sans", sans-serif;
}
h3 {
  font-family: "Open Sans", sans-serif;
}
.search {
  top: 6px;
  left: 10px;
}

.form-control {
  border: none;
  padding-left: 32px;
}

.form-control:focus {
  border: none;
  box-shadow: none;
}

.green {
  color: green;
}
.wrapper {
  overflow: scroll;
  height: 100vh;
}

hr {
  border: none;
  height: 5px;
  color: #333;
  background-color: #333;
}
