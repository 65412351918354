.App {
  background-color: white;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}
body {
  overflow: hidden; /* Hide scrollbars */
}
span {
  cursor: pointer;
}
.card {
  width: 400px;
  background-color: #fff;
  border: none;
  border-radius: 12px;
}

.form-control {
  margin-top: 10px;
  height: 48px;
  border: 2px solid #eee;
  border-radius: 10px;
}
.form-control:focus {
  box-shadow: none;
  border: 2px solid #039be5;
}
.wrapper {
  overflow: scroll;
  height: 100vh;
}

hr {
  border: none;
  height: 5px;
  color: #333;
  background-color: #333;
}

button {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  color: #0d6efd;
}
